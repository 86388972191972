import FormScreen from './FormScreen'
import CertificadosScreen from './CertificadosScreen'
import LoginScreen from './LoginScreen'
import CertificadosLoginScreen from './CertificadosLoginScreen'
import ChecklistsScreen from './ChecklistsScreen'
import ChecklistsLoginScreen from './ChecklistsLoginScreen'


let servicios = {
	"CONTROLVELOCIDAD" : {
		titulo:"Cambio de Límites",
		login_url:"http://192.175.109.132:8901/controlvel_login/",
		componente: FormScreen,
		componentelogin: LoginScreen,
	},
	"CERTIFICADOS" : {
		titulo:"Generación de Certificados",
		login_url:"//certificados.boltrack.net/dts/login/",
		componente: CertificadosScreen,
		componentelogin: CertificadosLoginScreen,
	},
	"CHECKLISTS" : {
		titulo:"Listado de Checklists",
		login_url:"//checklist.boltrack.net/api/cl_weblogin/",
		componente: ChecklistsScreen,
		componentelogin: ChecklistsLoginScreen,
	},
}

export default servicios["CHECKLISTS"];