import React from "react";
import "./styles.css";
import SETTINGS from './Settings';

class ChecklistsLoginScreen extends React.Component {
	initialState = {
		usuario: "",
		password: "",
		cargando: false,
		cargandoValidacion: false,
		codigo: "",
		enVistaDeCertificado: false,
		infoCertificado: null,
	};

	spinnerTimeoutID;

	constructor(props) {
		super(props);
		this.state = { ...this.initialState };
	}

	handleInputChange = event => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	};

	enviarDatos = () => {
		this.setState({ cargando: true }, () => {
			this.props.onSubmit(this.state.usuario, this.state.password);
			this.spinnerTimeoutID = setTimeout(() => {
				this.setState({ cargando: false });
			}, 1000);
		});
	};

	volverAPrincipal = () => {
		this.setState({ cargandoValidacion: false, enVistaDeCertificado: false })
	}

	componentWillUnmount() {
		if (this.spinnerTimeoutID) {
			clearTimeout(this.spinnerTimeoutID);
			this.spinnerTimeoutID = undefined;
		}
	}

	descargarCopia = () => {
		if (this.state.infoCertificado && this.state.infoCertificado.url) {
			window.open(this.state.infoCertificado.url, '_blank');
		}
	}

	render() {
		let mostrarSpinner = this.state.cargando;

		return (
			<>
				<h1>
					<img
						id="logo"
						src="https://gestiondeflota.boltrack.net/static/media/logo_bgwhite.ef3bb423.png"
						alt="logo Boltrack SRL"
					/>
				</h1>
				{!this.state.enVistaDeCertificado &&
					<>
						<h2>{SETTINGS.titulo}</h2>
						<div id="loginBox">
							<div className="fldSep">
								<span>Usuario:</span>
								<input
									id="usr"
									type="text"
									name="usuario"
									onChange={this.handleInputChange}
								/>
							</div>
							<div className="fldSep">
								<span>Password:</span>
								<input
									id="pwd"
									type="password"
									name="password"
									onChange={this.handleInputChange}
								/>
							</div>
							{this.props.errorTxt && (
								<span className="errorTxt">{this.props.errorTxt}</span>
							)}
							<button onClick={this.enviarDatos} className="positive">
								{mostrarSpinner ? (
									<img
										src="https://i.imgur.com/LSRNICX.gif"
										style={{ width: "40px" }}
										alt="spinner"
									/>
								) : (
										"INGRESAR"
									)}
							</button>
						</div>
						
					</>
				}
				
			</>
		);
	}
}

export default ChecklistsLoginScreen;
