import React from "react";
import "./styleform.css";
let $ = window.$;
export default class CertificadoImprimible extends React.Component {
	state = {
		cargandoInfoCertificado: false
	}
	exportar = () => {
		let props = this.props;
		let activo = props.data.activo;
		let fecha = props.data.fecha;
		let { tipoChecklist } = props.data;
		this.setState({
			cargandoInfoCertificado: true,
		})
		let tablehtml = $("#dataContainer").html();
		fetch('//checklist.boltrack.net/api/generar_pdf', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				titulo: [activo.CODIGO, activo.PLACA, fecha, tipoChecklist].filter(v => v).join("_"),
				body: tablehtml,
			})
		}).then((response) => {
			return response.json();
		}).then((data) => {
			console.log(data);
			if (data.status) {
				console.log(data.url);
				window.open(data.url, '_blank');
				this.setState({
					cargandoInfoCertificado: false,
				})
			} else {
				this.setState({
					cargandoInfoCertificado: false,
				});
				alert(data.mensaje);
			}

		}).catch(e => {
			console.log(e.message);
			console.log(e.stack);
			this.setState({
				cargandoInfoCertificado: false,
			})
		});

	}

	render() {
		let props = this.props;
		console.log(props);
		if (!props.data || !props.data.data) return false;
		let BRMData = props.data.data.BRM;
		let SNC = props.data.data.SNC;
		let activo = props.data.activo;
		let conductor = props.data.conductor;
		let usuario = props.data.usuario;
		let comercializadora = props.data.comercializadora;
		let fecha = props.data.fecha;
		let { kilometraje, obra, motivo, tipoChecklist } = props.data;
		let dataFirma = props.data.dataFirma;
		return (<>
			<button onClick={this.exportar} disabled={this.state.cargandoInfoCertificado} class="btnExportar">Exportar a PDF</button>
			<div id="dataContainer">
				{props.data && <><table className="tData">
					<tbody className="tDataBody">
						<tr className="tDataRow">
							<td className="tDataD1">Usuario:</td>
							<td colSpan="3" className="tDataD2">{usuario.NOMBRE_USUARIO}</td>
						</tr>
						<tr className="tDataRow">
							<td className="tDataD1">Conductor:</td>
							<td colSpan="3" className="tDataD2">{conductor.NOMBRE}</td>
						</tr>
						<tr className="tDataRow">
							<td className="tDataD1">Evento:</td>
							<td className="tDataD2">{tipoChecklist}</td>
							<td className="tDataD3">Fecha de Registro:</td>
							<td className="tDataD4">{fecha}</td>
						</tr>
						<tr className="tDataRow">
							<td className="tDataD1">Clase:</td>
							<td className="tDataD2">{activo.CLASE}</td>
							<td className="tDataD3">Motor:</td>
							<td className="tDataD4">{activo.MOTOR}</td>
						</tr>
						<tr className="tDataRow">
							<td className="tDataD1">Tipo:</td>
							<td className="tDataD2">{activo.TIPO}</td>
							<td className="tDataD3">Chasis:</td>
							<td className="tDataD4">{activo.CHASIS}</td>
						</tr>
						<tr className="tDataRow">
							<td className="tDataD1">Marca:</td>
							<td className="tDataD2">{activo.MARCA}</td>
							<td className="tDataD3">Cilindrada:</td>
							<td className="tDataD4">{activo.CILINDRADA}</td>
						</tr>
						<tr className="tDataRow">
							<td className="tDataD1">Modelo:</td>
							<td className="tDataD2">{activo.MODELO}</td>
							<td className="tDataD3">Industria:</td>
							<td className="tDataD4">{activo.INDUSTRIA}</td>
						</tr>
						<tr className="tDataRow">
							<td className="tDataD1">Año:</td>
							<td className="tDataD2">{activo.ANIO}</td>
							<td className="tDataD3">Kilometraje:</td>
							<td className="tDataD4">{kilometraje}</td>
						</tr>
						<tr className="tDataRow">
							<td className="tDataD1">Placa:</td>
							<td className="tDataD2">{activo.PLACA}</td>
							<td className="tDataD3">Codigo:</td>
							<td className="tDataD4">{activo.CODIGO}</td>
						</tr>
						<tr className="tDataRow">
							<td className="tDataD1">Motivo:</td>
							<td className="tDataD2">{motivo}</td>
							<td className="tDataD3">Obra:</td>
							<td className="tDataD4">{obra}</td>
						</tr>
					</tbody>
				</table></>}

				{(BRMData && BRMData.length) &&
					BRMData.map((categoria, i) => {
						return <><table className="tBRM">
							<thead className="tBRMHead">
								<tr className="tBRMHeadRow">
									<th className="tBRMHeadT">{categoria.nombre}</th>
									<th className="tBRMHeadB">B</th>
									<th className="tBRMHeadR">R</th>
									<th className="tBRMHeadM">M</th>
									<th className="tBRMHeadO">Observaciones</th>
								</tr>
							</thead>
							<tbody>
								{categoria.items.map((item, i) => {
									return <>
										<tr key={item.nombre} className="tBRMBodyRow">
											<td className="tBRMBodyT">{item.nombre}</td>
											<td className="tBRMBodyB">{item.valor === 'B' && "✔"}</td>
											<td className="tBRMBodyR">{item.valor === 'R' && "✘"}</td>
											<td className="tBRMBodyM">{item.valor === 'M' && "✘"}</td>
											<td className="tBRMBodyO">{item.obs}</td>
										</tr>
										{item.base64 && <tr>
											<td className="tIMGc" colspan="6">
												<img className="tIMGInCL" src={"data:image/png;base64, " + item.base64} alt="customimage" />
											</td>
										</tr>}
										{item.url && <tr>
											<td className="tIMGc" colspan="6">
												<img className="tIMGInCL" src={item.url} alt="customimage" />
											</td>
										</tr>}
									</>
								})}
							</tbody>
						</table></>
					})}
				<br />
				{(SNC && SNC.length) &&
					SNC.map((categoria, i) => {
						return <><table className="tSNC">
							<thead className="tSNCHead">
								<tr className="tSNCHeadRow">
									<th className="tSNCHeadT">{categoria.nombre}</th>
									<th className="tSNCHeadS">S</th>
									<th className="tSNCHeadN">N</th>
									<th className="tSNCHeadCant">Cant.</th>
									<th className="tSNCHeadO">Observaciones</th>
								</tr>
							</thead>
							<tbody>
								{categoria.items.map((item, i) => {
									return <>
										<tr key={item.nombre} className="tSNCBodyRow">
											<td className="tSNCBodyT">{item.nombre}</td>
											<td className="tSNCBodyS">{item.valor === 'S' && "✔"}</td>
											<td className="tSNCBodyN">{item.valor === 'N' && "✘"}</td>
											<td className="tSNCBodyCant">{item.cantidad}</td>
											<td className="tSNCBodyO">{item.obs}</td>
										</tr>
										{item.base64 && <tr>
											<td className="tIMGc" colspan="6">
												<img src={"data:image/png;base64, " + item.base64} alt="customimage" />
											</td>
										</tr>}
									</>
								})}
							</tbody>
						</table></>
					})}
				<table className="tData">
					<thead style={{textAlign:'center',fontWeight:'bold',width:'100%'}}>
						<th>Firma de Conformidad del Conductor/Operador</th>
					</thead>
					<tbody className="tDataBody">
						<tr className="tDataRow">
							{dataFirma && <td className="tIMGc">
								<img className="tIMGInCL" src={"data:image/png;base64, " + dataFirma} alt="firmaConfirmacion" />
							</td>}
						</tr>
					</tbody>
				</table>
			</div></>)
	}
}